import { assertInInjectionContext, effect, inject, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { switchMap, tap } from 'rxjs';
import { API_URL, SITE_TYPE } from '@nats/tokens';
import { createEffect } from 'ngxtension/create-effect';

@Injectable()
export class AnalyticsService {
	private http = inject(HttpClient);
	private apiUrl = inject(API_URL);
	private siteType = inject(SITE_TYPE);

	trackSetView = createEffect<{ setId: string }>(
		switchMap(({ setId }) => this.trackView({ cms_set_id: setId }))
	);

	trackMetadataView = createEffect<{ typeId: string; valueId: string }>(
		switchMap(({ typeId, valueId }) =>
			this.trackView({
				cms_data_type_id: typeId,
				cms_data_value_id: valueId,
			})
		)
	);

	private trackView(params: Record<string, string> = {}) {
		let path = '';
		if (this.siteType === 'area') {
			path = `${this.apiUrl}/openid/members/member_api.php/content/view`;
		} else if (this.siteType === 'tour') {
			throw new Error('trackView is not implemented');
		}

		return this.http
			.post<unknown>(path, new HttpParams({ fromObject: params }))
			.pipe(tap(() => console.log('trackView success', params)));
	}
}

/**
 * Tracks a view with the given params
 * @param params
 */
export function trackView(params: () => Record<string, string | null | undefined>) {
	assertInInjectionContext(trackView);

	const analytics = inject(AnalyticsService);

	let tracked = false;

	effect(() => {
		const p = params();
		if (!p || tracked) return;

		if (p['cms_set_id']) {
			analytics.trackSetView({ setId: p['cms_set_id'] });
			tracked = true;
		} else if (p['cms_data_type_id'] && p['cms_data_value_id']) {
			analytics.trackMetadataView({
				typeId: p['cms_data_type_id'],
				valueId: p['cms_data_value_id'],
			});
			tracked = true;
		}
	});
}
