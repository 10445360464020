import { inject, Injectable } from '@angular/core';
import { SITE_TYPE } from '@nats/tokens';

@Injectable({ providedIn: 'root' })
export class StyleLoader {
	private _loadedClasses = new Set<string>();

	classesMap = inject(SITE_TYPE) === 'tour' ? CLASS_TO_STYLE_MAP_TOURS : CLASS_TO_STYLE_MAP_MEMBERS;

	public loadStylesBasedOnSettings(settings: any): void {
		// split the row_attr_class string into an array of classes
		const classes = settings?.row_attr_class?.split(' ') ?? [];

		// load the styles for each class
		for (const className of classes) {
			// check if the class is valid
			if (!className) return;
			// check if the class is in the class to style map
			if (this.classesMap[className] === undefined) continue;

			this.loadStyle(className);
		}
	}

	private loadStyle(className: string): void {
		// check if the style has already been loaded
		if (this._loadedClasses.has(className)) return;

		// get the styleUrl from the class to style map
		const styleUrl = this.classesMap[className];

		// create a new link element and append it to the head
		const link = document.createElement('link');
		link.rel = 'stylesheet';
		link.href = styleUrl + '.css';
		document.head.appendChild(link);

		// add the styleUrl to the loaded styles
		this._loadedClasses.add(className);
	}
}

const CLASS_TO_STYLE_MAP_MEMBERS: Record<string, string> = {
	'cms-hero-wrap': 'cms-hero-wrap',
};

const CLASS_TO_STYLE_MAP_TOURS: Record<string, string> = {
	'cms-hero-wrap': 'cms-hero-wrap',
};
