import { inject, Injectable, Pipe, PipeTransform } from '@angular/core';
import { idToPageRoute } from '@nats/shared/pipes';
import { GLOBAL_CONFIG, PAGE_DATA } from '@nats/tokens';
import { Router } from '@angular/router';
import { injectQueryParams } from 'ngxtension/inject-query-params';

const ROUTE_ID_KEY = 'cms-route-page-id';
const ROUTE_PARAMS_KEY = 'cms-route-params';
const ACTIVE_ROUTE_CLASS_KEY = 'cms-active-route';
const DATA_NATS_ID_KEY = 'data-nats-id';

@Injectable()
export class HtmlBlockState {
	private globalConfig = inject(GLOBAL_CONFIG);
	private pageData = inject(PAGE_DATA);
	private router = inject(Router);
	private queryParams = injectQueryParams();

	private anchorTagsDataMap = new Map<string, { href: string; pageId: string; params: string[] }>();

	handleDelegatedClick(event: MouseEvent | PointerEvent) {
		// check if target is anchor tag
		if (!(event.target instanceof HTMLAnchorElement)) return;

		const id = event.target.getAttribute(DATA_NATS_ID_KEY);
		if (!id) return;

		const data = this.anchorTagsDataMap.get(id);
		if (!data) return;

		const { href, pageId, params } = data;

		let queryParams: Record<string, string> = {};

		if (params.includes('*')) {
			queryParams = this.queryParams();
		} else {
			params.forEach(param => {
				if (param.includes('=')) {
					const [key, value] = param.split('=');
					queryParams[key] = value;
				} else {
					queryParams[param] = this.queryParams()[param];
				}
			});
		}

		console.log({ href, pageId, params, queryParams });

		if (href) {
			this.router.navigate([href], { queryParams });
		} else if (pageId) {
			const routeLink = idToPageRoute(this.globalConfig, pageId);
			this.router.navigate([routeLink], { queryParams });
		}
	}

	annotateDOM(hostElement: HTMLElement) {
		hostElement.querySelectorAll('a').forEach(anchor => {
			const href = anchor.getAttribute('href');
			// http://example.com, https://example.com, //example.com, mailto:example.com, tel:1234567890, sms:1234567890
			const toBeIgnored = ['http://', 'https://', '//', 'mailto:', 'tel:', 'sms:'];
			if (toBeIgnored.some(prefix => href?.startsWith(prefix))) {
				anchor.classList.add('cms-link', 'cms-external-link');
				return; // ignore external links
			}

			const pageId = anchor.getAttribute(ROUTE_ID_KEY);
			const params = anchor.getAttribute(ROUTE_PARAMS_KEY);

			const id = randomId();

			this.anchorTagsDataMap.set(id, {
				href: href ?? '',
				pageId: pageId ?? '',
				params: params ? parseParams(params) : [],
			});

			anchor.setAttribute(DATA_NATS_ID_KEY, id); // set id attribute
			anchor.removeAttribute('href'); // remove href to not override routerLink
			anchor.style.cursor = 'pointer'; // change cursor to pointer

			anchor.classList.add('cms-link', 'cms-internal-link');

			if (pageId === this.pageData.cms_page_id || href === this.pageData.slug) {
				anchor.classList.add(ACTIVE_ROUTE_CLASS_KEY);
			}
		});
	}
}

@Pipe({
	name: 'replaceVariables',
	standalone: true,
})
export class ReplaceVariablesPipe implements PipeTransform {
	globalConfig = inject(GLOBAL_CONFIG);

	transform(html: string): string {
		return Object.keys(this.toBeReplaced).reduce((acc, key) => {
			return replaceAll(acc, key, this.toBeReplaced[key]);
		}, html);
	}

	toBeReplaced: Record<string, string> = {
		'{{links.full_signup_url}}': this.globalConfig.area.links?.full_signup_url || '',
		'{{links.signup_url}}': this.globalConfig.area.links?.full_signup_url || '',
		'{{links.login_url}}': this.globalConfig.area.links?.login_url || '',
		'{{links.natscode}}': this.globalConfig.area.links?.natscode || '',
	};
}

function replaceAll(str: string, find: string, replace: string) {
	return str.replace(new RegExp(find, 'g'), replace);
}

// params are "['search','q','test']" or "[]" or "['*']" or ""
const parseParams = (params: string) => {
	if (params === '') return [];
	if (params === '[]') return [];

	return params
		.slice(1, -1) // strip the first and last character [ and ]
		.split(',') // split by comma
		.map(param => param.trim()) // trim each item in the array
		.map(param => param.slice(1, -1)) // strip the first and last character ' and '
		.filter(param => param !== ''); // remove empty items
};

const randomId = () => Math.random().toString(36).substring(2, 20);
