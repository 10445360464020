import { ErrorHandler } from '@angular/core';

export class NatsErrorHandler extends ErrorHandler {
	override handleError(error: Error): void {
		if (error.name === 'ChunkLoadError') {
			// reload the page if a chunk fails to load
			// probably due to a new version of the app being deployed
			window.location.reload();
		} else {
			super.handleError(error);
		}
	}
}
